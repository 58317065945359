<!--
 * @Author: your name
 * @Date: 2020-06-20 17:41:59
 * @LastEditTime: 2020-09-08 10:41:02
 * @LastEditors: name
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\EBCHome\statisticalAnalysis\index.vue
-->
<template>
    <div class="statisticalAnalysis">
        <back></back>
        <div class="header">
            <template v-for="(item,index) in navData">
                <div
                    :key="index"
                    class="item item1"
                    :class="[{ active: selectNav == index }, 'item'+item.code]"
                    @click="navClick(item,index)"
                >
                    <span class="iconfont icongongdi" :class="item.icon"></span>
                    <span>{{ item.name }}</span>
                </div>
            </template>
            <div class="item item3">
                <div class="text">
                    中联集团商混专题分析
                </div>
            </div>
            <div class="item item4"></div>
            <div class="item item5"></div>
        </div>
        <div class="main">
            <component :is="comName"></component>
            <!-- <sourceAnalysis></sourceAnalysis> -->
        </div>
    </div>
</template>

<script>
import back from './../back';
import lifeCycle from './../../lifeCycle/index.vue';
import sourceAnalysis from './sourceAnalysis';
export default {
    components: { back, lifeCycle, sourceAnalysis },
    props: {},
    data() {
        return {
            navData: [{
                name: '工程生命周期',
                icon: 'icongongdi',
                code: '1',
            }, {
                name: '货源地分析',
                icon: 'iconweizhi',
                code: '2',
            }],
            selectNav: 0,
            comName: 'lifeCycle',
        };
    },
    watch: {},
    computed: {},
    methods: {
        navClick(data, i) {
            this.selectNav = i;
            if (data.code === '1') {
                this.comName = 'lifeCycle';
            } else {
                this.comName = 'sourceAnalysis';
            }
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus">
.statisticalAnalysis
    height 100%
    overflow hidden
    // background url(./../images/statisticalAnalysis.png) no-repeat
    background #0C274F
    background-size 100% 100%
    position relative
    .header
        height .6rem
        display flex
        position relative
        background-image none
        .item
            height 100%
            background-size 100% 100%
            background-repeat no-repeat
            text-align center
            line-height .6rem
            font-size .18rem
            color #3A69AA
            cursor pointer
            .iconfont
                margin-right .1rem
                vertical-align middle
                font-size .2rem
        .item1
            width 3.54rem
            background-image url(./../images/bg_l_1_normal.png)
            &.active
                background-image url(./../images/bg_l_1_highlight.png)
                color #24C7FB
        .item2
            position absolute
            left 3.18rem
            top 0
            width 3.54rem
            background-image url(./../images/bg_l_2_normal.png)
            &.active
                background-image url(./../images/bg_l_2_highlight.png)
                color #24C7FB
        .item3
            position absolute
            left 6.34rem
            top 0
            width 6.72rem
            background-image url(./../images/bg_c.png)
            background-size auto 0.71rem
            .text
                background linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(108,151,216,1) 100%)
                -webkit-background-clip text
                -webkit-text-fill-color transparent
                height 100%
                line-height .5rem
        .item4
            position absolute
            left 12.54rem
            top 0
            width 3.54rem
            background-image url(./../images/bg_r_2_noraml.png)
        .item5
            position absolute
            left 15.72rem
            top 0
            width 3.54rem
            background-image url(./../images/bg_r_1_normal.png)
    .main
        height calc(100% - .6rem)
</style>
